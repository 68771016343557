<template>
  <CustomButton type="minimal-light" shape="pill" size="sm" icon-size="sm">
    <template #icon>
      <svg class="h-[1.25rem]" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_560_33630)">
          <path d="M17.1668 16.6667V16.0417C17.1668 15.1024 16.7937 14.2015 16.1295 13.5373C15.4653 12.8731 14.5645 12.5 13.6252 12.5H7.37516C6.43585 12.5 5.53502 12.8731 4.87083 13.5373C4.20663 14.2015 3.8335 15.1024 3.8335 16.0417V16.6667" stroke="black" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M10.4998 9.16667C12.3408 9.16667 13.8332 7.67428 13.8332 5.83333C13.8332 3.99238 12.3408 2.5 10.4998 2.5C8.65889 2.5 7.1665 3.99238 7.1665 5.83333C7.1665 7.67428 8.65889 9.16667 10.4998 9.16667Z" stroke="black" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
          <clipPath id="clip0_560_33630">
            <rect width="20" height="20" fill="white" transform="translate(0.5)"/>
          </clipPath>
        </defs>
      </svg>
    </template>
  </CustomButton>
</template>

<script setup>
import {useTranslations} from "@i18n/utils.js";
import CustomButton from "@components/vue-elements/CustomButton.vue";

const {t} = useTranslations();
</script>